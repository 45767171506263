* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.modal {
  position: fixed; /* Stay in place */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  overflow-y: hidden;
  overflow-x: hidden;
   /* background: #f3f4f6;  */
  background: #000;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dashboard-container::-webkit-scrollbar,
.overflow-x-scroll::-webkit-scrollbar,
.scrolls::-webkit-scrollbar {
  /* display: none; */
}

/* Hide scrollbar for IE, Edge and */
.dashboard-container,
.overflow-x-scroll,
.scrolls {
  /* -ms-overflow-style: none; 
  scrollbar-width: none;  */
}

.sidebar-container,
.overflow-x-scroll,
.scrolls {
  /* -ms-overflow-style: none; 
  scrollbar-width: none; */
}

.sidebar-container::-webkit-scrollbar,
.overflow-x-scroll::-webkit-scrollbar,
.scrolls::-webkit-scrollbar {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
